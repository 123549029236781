.max-width-100 {
  max-width: 100%;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.App main {
  height: 100%;
}

.page-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.title-absolute {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  margin-left: 1rem;
}
